<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h5>
            {{
              $t('reporting.statusReportName', [
                $t(`feedbacks.processTypes.${$route.params.processType}`)
              ])
            }}
          </h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div>
        <v-row>
          <v-col cols="12" md="4">
            <user-selection
              @userSelected="onTargetUserSelected"
              :disabled="loading"
            ></user-selection>
          </v-col>
          <v-col cols="12" md="4" offset-md="2">
            <v-select
              v-model="talkroundId"
              :items="talkroundItems"
              :label="$t('feedbacks.talkround')"
              :disabled="loading"
            ></v-select>
          </v-col>
        </v-row>

        <div class="text-center" v-if="loading">
          <div class="text-uppercase muted mb-4 mt-4">
            {{ $t('dataLoading') }}
          </div>
          <v-progress-linear indeterminate></v-progress-linear>
        </div>

        <template v-else>
          <status-chart
            :chart-data="statusReport"
            :process-type="parseInt($route.params.processType)"
          ></status-chart>

          <div class="text-right">
            <v-btn @click="downloadReport" :loading="downloading">
              <v-icon class="mr-2">mdi-file-excel-outline</v-icon>
              {{ $t('reporting.download') }}
            </v-btn>
          </div>

          <status-report-table
            :headers="headers"
            :report-rows="reportRows"
          ></status-report-table>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import reportingService from '@/services/ReportingService.js';
import talkroundService from '@/services/TalkroundService.js';
import statusReportTable from '@/components/reporting/status-report-table.vue';
import statusChart from '@/components/reporting/status-chart.vue';
import userSelection from '@/components/reporting/report-user-selection.vue';

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    loading: true,
    downloading: false,
    statusReport: null,
    reportData: null,
    talkrounds: [],
    talkroundId: null,
    targetUserId: null
  }),

  computed: {
    ...mapGetters({
      workflowSettings: 'workflowSettings'
    }),

    availableStates() {
      const processType = this.$route.params.processType;
      const processSettings = this.workflowSettings.find(
        (x) => x.processType === processType.toString()
      );

      if (!processSettings) {
        return [];
      }

      return processSettings?.statusList?.map((x) => x.statusId);
    },

    headers() {
      const data = [...this.reportData.result.columns];

      return data
        .filter((x) => x.showInUI)
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map((x) => {
          return {
            index: x.sortIndex,
            label: x.itemName,
            type: x.dataType
          };
        });
    },

    talkroundItems() {
      const typeId = this.$route.params.processType;

      return this.talkrounds
        .filter((x) => x.processType == typeId)
        .map((x) => {
          const start = this.$d(new Date(x.startDateUtc), 'date');
          const end = this.$d(new Date(x.endDateUtc), 'date');

          return {
            value: x.id,
            text: `${start} - ${end}`
          };
        });
    },

    reportRows() {
      return [...this.reportData.result.rows].sort((a, b) => {
        // sort by last name
        if (a.columns[3].toLowerCase() > b.columns[3].toLowerCase()) {
          return 1;
        } else if (a.columns[3].toLowerCase() < b.columns[3].toLowerCase()) {
          return -1;
        } else {
          // and then by first name
          if (a.columns[4].toLowerCase() > b.columns[4].toLowerCase()) {
            return -1;
          } else if (a.columns[4].toLowerCase() < b.columns[4].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
  },

  watch: {
    async talkroundId() {
      this.loading = true;
      await this.loadReport(this.targetUserId);
    }
  },

  async mounted() {
    this.talkrounds = await this.listTalkrounds();
    this.talkroundId = this.getTalkroundId();

    await this.loadReport();
  },

  methods: {
    async downloadReport() {
      this.downloading = true;

      const processType = this.$route.params.processType;

      const data = await reportingService.downloadReport(
        this.$route.params.reportType,
        processType,
        this.talkroundId,
        true,
        this.targetUserId
      );

      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;

      const processTypeLabel = this.$t(`feedbacks.processTypes.${processType}`);
      const today = new Date().toISOString().slice(0, 10).replaceAll('-', '');
      anchor.download = `${today}_StatusReport_${processTypeLabel}.xlsx`;

      anchor.click();

      window.URL.revokeObjectURL(fileUrl);

      this.downloading = false;
    },

    getTalkroundId() {
      const typeId = this.$route.params.processType;

      var processTalkrounds = this.talkrounds.filter(
        (x) => x.processType == typeId
      );

      if (processTalkrounds.length === 1) {
        return processTalkrounds[0].id;
      }

      var activeTalkrounds = processTalkrounds.filter((x) => x.isActive);
      if (activeTalkrounds.length > 0) {
        return activeTalkrounds[0].id;
      }

      return processTalkrounds[0].id;
    },

    async listTalkrounds() {
      const processType = this.$route.params.processType;

      let talkrounds = await talkroundService.listTalkrounds();
      talkrounds = talkrounds.filter((x) => x.processType == processType);

      return talkrounds;
    },

    async loadReport(targetUserId) {
      this.reportData = await reportingService.createReport(
        this.$route.params.reportType,
        this.$route.params.processType,
        this.talkroundId,
        true,
        targetUserId
      );

      this.statusReport = reportingService.getStatusReport(
        this.reportData.result.rows,
        10,
        this.availableStates
      );

      this.loading = false;
    },

    async onTargetUserSelected(targetUserId) {
      this.loading = true;
      this.targetUserId = targetUserId;
      await this.loadReport(targetUserId);
    }
  },

  components: {
    statusReportTable,
    statusChart,
    userSelection
  }
};
</script>
